@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background-color: $black10;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
}

.label {
  text-transform: uppercase;
  color: $black40;
  font-weight: $boldFontWeight;
  margin-left: 16px;
}
