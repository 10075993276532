@import 'src/styles/variables';

.expander {
  cursor: pointer;
  overflow: hidden;

  .contentOpen {
    transform: scaleY(1);
    height: auto;
  }

  .selectArrowOpen {
    transition: 0.2s ease-in-out;
    transform: translateY(-50%) rotate(180deg);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.2s ease-in-out;
  height: 0;
  color: $black60;
}

.selectArrow {
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: translateY(-50%);
  transform-origin: center;
}

.arrowContainer {
  display: flex;
}

.ellipse {
  background-color: $deepPurple;
  height: 25px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding-top: 15px;

  .selectArrow {
    width: 100%;
    filter: invert(100%);
  }
}
