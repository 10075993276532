@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid $black10;
  box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
  border-radius: 12px;
}

.container:not(:last-child) {
  margin-bottom: 20px;
}

.label {
  width: 80%;
  margin: 20px 0;
  color: $black60;
  text-align: center;
}
