@import 'src/styles/mixins';

.button {
  margin-top: 32px;
}

.note {
  margin: 30px 0;
  font-size: 12px;
  font-weight: 300;
}
