@import 'src/styles/variables';
@import 'src/styles/mixins';

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  @include stepsContainer;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  @include body-text('xl', 700);
  text-align: center;
  margin-bottom: 12px;
}

.galesTitle {
  display: flex;
  align-items: center;

  .galesIcon {
    margin-left: 4px;
  }
}

.subtitle {
  color: $black80;
  @include body-text('md', 400);
  text-align: center;
  margin-bottom: 32px;
}

.rewardsStepper {
  margin: 32px 0;
}

.reward {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 24px;
}

.iconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: $black10;
  border-radius: 50%;
  z-index: 1;

  > svg {
    path {
      fill: $black40;
    }
    width: 24px;
    height: 24px;
  }
}

.reward:not(:last-child)::after {
  content: '';
  position: absolute;
  left: calc(20px - 1px); // subtract width / 2
  top: 40px;
  width: 2px;
  height: 100%;
  background: $black10;
}

.reward.rewardCompleted::after {
  background-color: $green;
}

.reward.rewardCompleted:has(+ .rewardActive)::after {
  background: linear-gradient(180deg, $green 0%, $irisLight 70%);
}

.reward.rewardActive:has(+ .reward)::after {
  background: linear-gradient(180deg, $irisLight 0%, $black10 70%);
}

.rewardCompleted {
  .rewardTitle {
    color: $black100;
  }
}

.rewardActive {
  .rewardTitle {
    color: $black100;
  }
}

.rewardActive .iconContainer {
  background-color: $irisLight;

  svg {
    path {
      fill: $deepPurple;
    }
  }
}

.rewardCompleted .iconContainer {
  background-color: $green;

  svg {
    path {
      fill: none;
      stroke: $white;
    }
  }
}

.rewardData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.rewardTitle {
  font-size: 20px;
  font-weight: $mediumFontWeight;
  line-height: 40px;
  margin-bottom: 8px;
  color: $black40;
}

.rewardDescription {
  @include body-text('sm', 400);
  color: $black80;
  text-align: left;
  max-width: 500px;

  li {
    list-style-position: inside;
  }
}

.rewardShowMore {
  margin-top: 24px;
}

.highlighted {
  cursor: pointer;
  color: $deepPurple;
  font-weight: $mediumFontWeight;
}

.strong {
  color: $black80;
  font-weight: $boldFontWeight;
}

.visitLink {
  margin-top: 12px;

  a {
    text-decoration: none;
    color: $deepPurple;
  }
}

.galesShoes {
  display: block;
  width: 100%;
  margin-top: 12px;
}

.inviteTitle {
  font-size: 20px;
  font-weight: $mediumFontWeight;
  margin-bottom: 8px;
}

.button {
  margin: 20px 0 0;
}

.bold {
  font-weight: $boldFontWeight;
}
