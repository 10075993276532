@import 'src/styles/variables';
@import 'src/styles/mixins';

$stepperLineWidth: 65px;

.container {
  @include stepsContainer;
  text-align: center;
  padding: 16px 24px;
  margin-bottom: 16px;

  @include sm {
    margin-bottom: 16px;
  }
}

.container.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  @include h1;
}

.subtitle {
  @include body-text('md');
  margin: 12px 0;
}

.bold {
  font-weight: $boldFontWeight;
}

.cardPreviewContainer {
  position: relative;
  width: 100%;
  max-width: 293px;
  margin: 0 auto;

  .cardText {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    bottom: 16%;
    left: 7%;
  }
}

.thanksLabel {
  @include body-text('md');
  margin: 12px 0 24px;
  color: $black80;
}

.stepperContainer {
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepperItem {
  position: relative;

  &:not(:last-child) {
    margin-right: $stepperLineWidth;
  }
}

.iconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: $black10;
  border-radius: 50%;

  > svg {
    path {
      fill: $black40;
    }
    width: 24px;
    height: 24px;
  }
}

.stepperItem:not(:last-child) .iconContainer::after {
  content: '';
  position: absolute;
  left: 100%;
  height: 2px;
  width: $stepperLineWidth;
  background-color: $black10;
}

.rewardCompleted.stepperItem .iconContainer::after {
  background-color: $green;
}

.rewardCompleted.stepperItem:has(+ .rewardActive) .iconContainer::after {
  background: linear-gradient(90deg, $green 0%, $irisLight 70%);
}

.rewardActive.stepperItem:has(+ .reward) .iconContainer::after {
  background: linear-gradient(180deg, $irisLight 0%, $black10 70%);
}

.rewardActive .iconContainer {
  background-color: $irisLight;

  svg {
    path {
      fill: $deepPurple;
    }
  }
}

.rewardCompleted .iconContainer {
  background-color: $green;

  svg {
    path {
      fill: none;
      stroke: $white;
    }
  }
}

.gold {
  color: $gold;
}

.silver {
  color: $white;
}

.purple {
  color: $white;
}
