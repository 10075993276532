@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include offerContainer(true);
  color: $black100;
  margin-left: -16px;
  box-sizing: content-box;
  width: 100%;

  @include sm {
    margin: 0 auto;
    padding: 40px 160px 32px;
  }
}

.title {
  @include h1;
  text-align: center;
  margin-bottom: 8px;
}

.subtitle {
  @include body-text(lg);
  text-align: center;
  margin-bottom: 30px;

  .highlighted {
    @include body-text(lg, $boldFontWeight);
  }
}

.detailsText {
  @include body-text(lg);
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 36px;
}

.nextButton {
  background: $deepPurple;
  border-radius: 12px;
}
