@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 640px;
  margin: 0 auto 38px;
  z-index: $contentIndex;

  .title {
    @include h1;
    text-align: center;
    margin-bottom: 24px;
  }

  .loanAmountPickerLabel {
    color: $black80;
  }

  // Removes margin between content and the screen on mobile view
  @media screen and (max-width: 670px) {
    box-sizing: content-box;
    margin-left: -16px;
  }

  @include sm {
    max-width: 720px;
    margin: 0 auto 48px;
    padding: 40px 120px 56px;
  }
}

.backLink {
  @include backButton;
  z-index: $contentIndex;
}

.header {
  display: flex;
  justify-content: center;
}

.editButton {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-weight: $mediumFontWeight;
  font-size: 16px;
  color: $blue;
}

.loanAmount {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

.iconContainer {
  display: flex;
  margin: 0 16px;
}

.disabledIcon {
  opacity: 0.5;
}

.offersContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  position: relative;

  &::before {
    content: 'vs';
    z-index: 1;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background-color: $black6;
    color: $black80;
    border: 1px solid $black10;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: $mediumFontWeight;
  }
}

.applyButton {
  background-color: #795af7;
}

.footnote {
  color: #1a1e23;
  margin-bottom: 24px;
  text-align: center;
}

.savings {
  color: $primaryColor;
}
