@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
  border-radius: 12px;
  margin-bottom: 36px;
  text-align: center;

  @include sm {
    max-width: 650px;
  }

  .header {
    padding: 24px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    margin: 0 16px;

    .headerTitle {
      .title {
        @include body-text(lg, 700);
      }

      .consolidationAmount {
        @include body-text(md);

        .availableAmount {
          color: $black40;
        }

        .amountError {
          font-weight: $semiBoldFontWeight;
          color: $red;
        }
      }
    }

    .dropdownButton {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
      background-color: $deepPurple;
      border-radius: 100%;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 150ms linear;

      &.isToggled {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .content.dropdownOpen {
    height: auto;
  }

  .content {
    height: 0;
    overflow: hidden;
    transition: height 200ms linear;

    .recommended {
      margin-top: 24px;
      @include body-text(md, 600);
    }

    .subtitle {
      @include body-text(md);
      margin: 0 0 12px 8px;
    }

    .hint {
      border-top: 1px solid $black10;
      padding: 12px 16px 0;
      @include body-text(md, 400);
      color: $black60;
      font-style: italic;
      text-align: center;

      .hintText {
        margin-bottom: 8px;
      }

      .hintText:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.content::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
  background-color: #00000022;
  border: 1px solid #ffffff40;
  border-radius: 20px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
