@import 'src/styles/variables';
@import 'src/styles/mixins';

.items {
  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    display: flex;
    flex-direction: column;
    width: 158px;
    height: 134px;
    align-items: center;
    justify-content: center;

    background: $white;

    border: 1px solid $black10;

    box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
    border-radius: 12px;

    .text {
      margin-top: 16px;
      @include body-text('md');
    }

    &:first-of-type {
      margin-right: 12px;
    }
  }

  .plus {
    margin-left: -24px;
    margin-right: -12px;
    z-index: 999;
  }

  .arrowIcon {
    width: 32px;
    transform: rotate(270deg);

    margin-left: -24px;
    margin-right: -12px;
    z-index: 999;
  }
}

.subtext {
  color: $black80;
  text-align: center;
  margin: 24px 8px;
  @include body-text('md');
}
