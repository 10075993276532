@import 'src/styles/mixins';
@import 'src/styles/variables';

.offerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 50%;
  padding: 16px;

  box-sizing: border-box;

  background: $white;
  border: 1px solid $black10;
  /* Drop Shadow / Active Card */

  box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
  border-radius: 12px;
}

.offerCard:first-child {
  margin-right: 14px;
}

.selectedCard {
  border: 3px solid $violet;
  box-sizing: border-box;
  box-shadow: 0 3px 10px rgba(48, 52, 101, 0.07);
  border-radius: 12px;
}

.disabledCard {
  background: $black3;
  border: 1px solid $black10;
  box-sizing: border-box;
  border-radius: 10px;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.title {
  font-weight: $regularFontWeight;
  font-size: 16px;
  text-align: center;
  color: $black80;
  width: 130px;

  &.highlighted {
    color: $black100;
  }
}

.primaryText {
  font-weight: $boldFontWeight;
  font-size: 16px;
  color: $black100;
}

.secondaryText {
  font-weight: $mediumFontWeight;
  font-size: 14px;
  color: $black60;
}

.savings {
  height: 24px;
  max-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.savingsText {
  font-weight: $boldFontWeight;
  font-size: 16px;
  color: #795af7;
  margin-right: 8px;
  white-space: nowrap;
}

.comparedToText {
  font-weight: $semiBoldFontWeight;
  font-size: 16px;
  color: #454b54;
  margin-right: 8px;
  white-space: nowrap;
}

.tooltip {
  width: 164px;
  background-color: $black60 !important;
  opacity: 1 !important;
  border-radius: 4px;
}

.tooltipText {
  font-weight: $regularFontWeight;
  font-size: 14px;
}

.discardedText {
  font-weight: $regularFontWeight;
  font-size: 16px;
  text-decoration-line: line-through;
  color: $black60;
  margin-right: 6px;
}
