@import 'src/styles/media';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.countrySelect {
  margin-bottom: 24px;
  @include sm {
    margin-bottom: 24px;
  }
}
