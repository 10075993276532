@import 'src/styles/mixins';

.container {
  margin-top: 24px;
}

.inputs {
  margin-bottom: 40px;
  width: 100%;
}

.formInput:not(:last-child) {
  @include formInputMargin;
}
