@import 'src/styles/media';
@import 'src/styles/variables';

.commonQuestions {
  margin: 0 auto 24px;
  width: 100%;
  z-index: $contentIndex;

  @include sm {
    max-width: 720px;
  }
}

.heading {
  text-align: center;
  margin: 24px 0;
}

.questions {
  background-color: $white;

  border: 1px solid $black10;
  box-sizing: border-box;
  box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
  border-radius: 12px;
}

.selectArrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
}

.label {
  position: relative;
  margin: 24px 0;
}

.expanderContainer {
  margin: 0 24px;

  &:not(&:last-child) {
    border-bottom: 1px solid $black6;
  }
}

.expanderOpen {
  padding-bottom: 24px;
}
