@import 'src/styles/mixins';

.inputs {
  width: 100%;
  margin-bottom: 24px;

  @include sm {
    margin-bottom: 40px;
  }
}

.consent {
  @include subtitle;
  margin-bottom: 24px;
}

.checkbox {
  margin-bottom: 16px;
}
