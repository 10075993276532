@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include offerContainer($background: true);
  height: 60vh;
  width: 100%;
  color: $black100;
  margin-left: -16px;
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: content-box;

  @include sm {
    margin: 24px auto 48px;
    padding: 40px 160px 32px;
  }
}
