@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: $white;
  border: 1px solid $black10;
  box-shadow: 0 16px 20px rgba(36, 85, 158, 0.08);
  border-radius: 12px;
  padding: 24px 16px;
  margin-top: 12px;
}

.header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerLabel {
  @include body-text(lg, $boldFontWeight);
}

.dropdownButton {
  width: 36px;
  height: 36px;
  background-color: $primaryColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms linear;

  &.isToggled {
    transform: rotate(180deg);
  }
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 200ms linear;
}

.content.dropdownOpen {
  margin-top: 24px;
  max-height: 400px;
}

.accountItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.accountItemName {
  text-transform: capitalize;
  color: $black60;
}

.accountItemContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.accountItemValue {
  text-transform: capitalize;
  text-align: right;
  @include body-text(md, $boldFontWeight);
}

.copyIcon {
  cursor: pointer;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
