@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputContainer {
  position: relative;
}

.input {
  @include input;
}

.label {
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 6px 8px;
  color: $black80;
}

.error {
  border: 1px solid $red;
}
