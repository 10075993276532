@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputs {
  width: 100%;
}

.button {
  margin-top: 72px;
}
