@import 'src/styles/mixins';
@import 'src/styles/variables';

:global(.react-datepicker) * {
  color: $black100 !important;
}

:global(.react-datepicker__month-text) {
  line-height: 21px;
}

.inputContainer {
  position: relative;

  .input + button {
    right: 4px;
    height: 60px;

    &::after {
      background-color: $primaryColor;
    }
  }
}

.input {
  @include input;
}

.label {
  @include inputLabel;
}

.error {
  border: 1px solid $red;
}
