@import 'src/styles/media';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: $regularFontWeight;
  font-size: 14px;
  line-height: 18px;
  color: $black60;
  margin: 24px auto 0;
  padding-bottom: 16px;
  width: 90%;

  @include sm {
    padding-bottom: 33px;
    font-size: 16px;
    max-width: 720px;
  }

  .podiatristsText {
    color: $black100;
    margin-bottom: 20px;
  }

  .disclaimerText {
    font-weight: $regularFontWeight;
    font-size: 12px;
    color: $black60;
    margin: 8px 0 24px;
    width: 100%;
    text-align: center;
  }

  .copyright {
    margin-top: 24px;
  }

  .contactButton {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @include body-text(sm, $semiBoldFontWeight);

    .icon {
      margin-right: 8px;
      width: 28px;
    }
  }
}
