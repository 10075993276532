@import 'src/styles/variables';
@import 'src/styles/mixins';

.subtitle {
  @include subtitle;
}

.descriptionContainer {
  margin-bottom: 72px;
  width: 100%;
}

.formInput:not(:last-child) {
  margin-bottom: 36px;
}

.backLink {
  @include backButton;
}
