@import 'src/styles/variables';
@import 'src/styles/mixins';

.giftBoxesContainer {
  background-color: $black6;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.giftBoxIcon {
  flex: 1 0 13%;

  rect:first-of-type {
    fill: $black3;
  }

  &.purpleBox {
    rect:first-of-type {
      fill: $deepPurple;
    }
  }
}

.giftCountContainer {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.giftCountText {
  @include body-text('xs', $boldFontWeight);
  text-transform: uppercase;
  color: $black80;
  letter-spacing: 0.4px;
}

.giftCountTextLight {
  @include body-text('xs');
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.strong {
  color: $black80;
  font-weight: $boldFontWeight;
}
