@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innerContainer {
  @include stepsContainer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @include sm {
    margin-bottom: 16px;
  }
}

.planneryCardInfoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: $black100;

  ul {
    text-indent: -1em;
    list-style: none;
    padding: 0 36px;
    margin: 20px 0;
  }

  ul li::before {
    content: '\2022';
    display: inline-block;
    width: 1em;
  }
}

.statusButton {
  margin-bottom: 20px;
}

.sectionTitle {
  @include body-text('lg', $boldFontWeight);
}

.totalDebtTitle {
  @include body-text('lg');
}

.totalDebtAmount {
  @include body-text('xl', $boldFontWeight);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header.centered {
  justify-content: center;

  .sectionTitle {
    margin-right: 20px;
  }
}

.header .centered {
  display: flex;
  justify-content: center;
}

.offerLabel {
  @include body-text('md');
  margin-top: 24px;
  text-align: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.highlighted {
  font-weight: $boldFontWeight;
}

.button {
  margin-top: 24px;
  width: 100%;
}

// Categories list
.row {
  width: 100%;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid $black6;
  transition: height 1s linear;

  &:last-child {
    border-bottom: 0;
    margin-bottom: -16px;
  }
}

.overchargedList {
  &:last-child {
    border-bottom: 0;
  }
}

.rowHeader {
  cursor: pointer;
  display: flex;

  :first-child {
    flex: 1;
  }
}

.rowHeader > div {
  display: flex;
  flex-direction: column;
}

.rowText {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.rowHeaderRight {
  display: flex;
  justify-content: center;
  align-items: baseline !important;
  margin-bottom: 4px;
}

.rowTitle {
  @include body-text('md');
}

.sparkLine {
  height: 8px;
  border-radius: 8px;
  background-color: $black6;
  width: 100%;

  .sparkLineValue {
    height: 8px;
    border-radius: 8px;
    transition: width 1s cubic-bezier(0.74, 0.72, 0.82, 1);
  }
}

.rowApr {
  @include body-text('sm');
  color: $black40;
  margin-right: 8px;
}

.rowBalance {
  @include body-text('md');
}

.dropdownIcon {
  margin-left: 12px;
  width: 20px;
  height: 20px;
  transition: transform 500ms ease;

  > path {
    stroke: $primaryColor;
  }

  &.expanded {
    transform: rotate(180deg);
  }
}

.dropdownContent {
  overflow: hidden;
  transition: height 400ms ease;
  margin-left: 12px;
}

// Account list
.accountRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 24px 12px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .accountName {
    @include body-text('md');
  }

  .accountAmount {
    @include body-text('md');
  }
}

.accountRow > div {
  display: flex;
  width: 75%;
  margin-right: 8px;
}

.accountRow .accountName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: flex-end;
}

.accountLogo {
  margin-right: 10px;
  width: 40px;
  min-width: 40px;
  height: 24px;
  border-radius: 50%;
  object-fit: contain;
}

.defaultLogo {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareButton {
  margin-top: 16px;
}
