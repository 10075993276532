@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: flex;
  position: relative;
  justify-content: center;
}

.comparisonLabel {
  background-color: $black6;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $black10;
  line-height: 36px;
  font-size: 16px;

  position: absolute;
  top: 12px;
}

.tableContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tableColumn {
  background-color: $white;
  border: 1px solid $black10;
  border-radius: 12px;
  padding: 20px;
  width: 158px;
  box-sizing: border-box;

  &:first-child {
    margin-right: 12px;
  }

  @include sm {
    width: 50%;
  }
}

.title {
  @include body-text('lg');
  margin-bottom: 24px;
  text-align: center;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.amount {
  font-weight: $boldFontWeight;
}

.label {
  @include body-text('md');
  color: $black60;
}
