@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.checkIcon {
  margin-bottom: 24px;
}

.text {
  @include h2;
  max-width: 560px;
  text-align: center;
  margin-bottom: 36px;
}
